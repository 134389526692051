<template>
  <v-card v-bind="$attrs">
    <v-card-text class="pa-0 ma-0">
      <v-card-title class="text-h6">
        Monitoring Dashboard
        <v-spacer></v-spacer>
        <v-menu
          v-model="filterDialog"
          :close-on-content-click="false"
          transition="scale-transition"
          max-height="90vh"
          max-width="400"
        >
          <template #activator="{ on }">
            <v-badge
              overlap
              class="mr-4 ml-1"
              :content="parcelsComputed.length"
              v-on="on"
              :value="parcelsComputed.length"
            >
              <v-btn v-on="on" class="map-setting" icon>
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <v-card class="mx-auto">
            <v-card-title>
              Filter by
              <v-spacer></v-spacer>
              <v-btn
                @click="reset()"
                color="indigo lighten-4"
                text
                class="text-capitalize v-btn--active"
                x-small
                ><span class="indigo--text">Reset</span></v-btn
              >
              <v-btn @click="filterDialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div>
                <h5 class="text-h6">Teams</h5>
                <base-filter
                  class="mt-1"
                  :items="teamsIncludingAll"
                  defaultColor=""
                  active-color="indigo"
                  active-dark
                  flat
                  :showTitle="false"
                  v-model="filter.team"
                  :showClearButton="false"
                ></base-filter>
              </div>
              <div>
                <h5 class="text-h6">Preserve</h5>
                <base-filter
                  class="mt-1"
                  :items="preserves"
                  defaultColor=""
                  active-color="primary"
                  flat
                  :showTitle="false"
                  v-model="filter.preserve"
                  :showClearButton="false"
                ></base-filter>
              </div>
              <div>
                <h5 class="text-h6">Ownership</h5>
                <base-filter
                  :default="['']"
                  multiple
                  class="mt-1 mb-0"
                  :items="landTypes"
                  item-style
                  defaultColor=""
                  outlined
                  flat
                  :showTitle="false"
                  v-model="filter.ownership"
                  :showClearButton="false"
                ></base-filter>
              </div>
              <v-divider></v-divider>
              <div class="d-flex align-center mt-2">
                <h6 class="text-h6 total-title">Total:</h6>
                <ParcelStat small :value="parcelsComputed" />
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-card-title>
    </v-card-text>

    <v-card-text class="pt-0 mt-0">
      <div class="chart-container">
        <div>
          <canvas ref="chart" width="100" id="chart"></canvas>
        </div>
        <div>
          <v-btn-toggle v-model="mode" mandatory color="success">
            <v-btn class="text-capitalize" x-small value="count">Count</v-btn>
            <v-btn class="text-capitalize" x-small value="percentage">Percentage</v-btn>
          </v-btn-toggle>
        </div>
        <div class="mt-3">
          <div v-for="team in chartData.data.labels" :key="team" class="text-capitalize">
            {{ team }}: {{ teamMembers(team).join(', ') }}
          </div>
        </div>
        <v-checkbox
          v-model="sort"
          true-value="desc"
          false-value="team"
          :label="`Sort by % done`"
        ></v-checkbox>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import Land from '../../services/land';
import Parcel from '../../services/parcel';
import { landFilter, landPreserveFilter, teamFilter, teams } from '../../utils/parcel';
import Organization from '../../services/organization';
import { sort } from '../../utils';
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    members: {
      type: Object,
      required: true
    }
  },
  components: {
    ParcelStat: () => import('../../components/Parcel/ParcelStat.vue')
  },
  data() {
    return {
      mode: 'percentage', //percentage, count
      sort: 'team', //desc, team
      preserves: [],
      parcels: [],
      filterDialog: false,
      landTypes: [{ text: 'All', value: '', style: { color: 'primary' } }, ...Land.landTypes()],
      loading: false,
      filter: {
        ownership: ['feeSimple', 'cr'],
        team: '',
        preserve: ''
      },
      chartData: {
        type: 'bar',
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.raw}${context.dataset.percentage ? '%' : ''} ${
                    context.dataset.key
                  }`;
                }
              }
            },
            title: {
              // display: true,
              // text: ''
            }
          },
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Teams'
              },
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        },
        data: {
          labels: [],
          datasets: []
        }
      },
      context: null,
      chartObj: null
    };
  },
  computed: {
    parcelsComputed() {
      let res = teamFilter(this.parcels, this.filter.team);
      res = landFilter(res, this.filter.ownership);
      res = landPreserveFilter(res, this.filter.preserve);
      return res;
    },
    teams() {
      return teams(this.parcels);
    },
    teamsIncludingAll() {
      return teams(this.parcels, true);
    },
    dataset() {
      const res = [];
      this.parcelsComputed.forEach(p => {
        const data = {
          mdd: p.mdd,
          lmd: p.lmd,
          monitoringFrequency: p.monitoringFrequency,
          team: p.team,
          daysRemaining: Land.monitoringDays(p.mdd)
        };
        res.push(data);
      });
      return res.filter(parcel => !!parcel.team);
    },
    teamList() {
      const teamList = new Set();

      Object.keys(this.members.team).forEach(email => {
        const teams = (this.members.team[email] || '')
          .split(',')
          .filter(i => !!i)
          .map(i => i.trim());
        teams.forEach(team => {
          teamList.add(team);
        });
      });

      return [...teamList].map(i => (i || '').trim()).sort();
    },
    membersComputed() {
      const res = {};
      return res;
    }
  },
  mounted() {
    this.fetchParcels();
    this.fetchPreserves();
    this.initChart();
  },
  methods: {
    teamMembers(team) {
      if (!this.members || !this.members?.members) return [];
      let users = new Set();

      this.members.members.forEach(member => {
        const teams = (member.team || '')
          .split(',')
          .filter(i => !!i)
          .map(i => i.trim());
        if (member.email && teams.includes(team)) {
          users.add(String(member.fullName || member.email).toLowerCase());
        }
      });

      users = [...users];
      return users.sort();
    },
    reset() {
      this.filter = {
        ownership: ['feeSimple', 'cr'],
        team: '',
        preserve: ''
      };
    },
    fetchPreserves() {
      const org = new Organization();
      org.landPreserves(this.id).then(data => {
        this.preserves = [
          { text: 'All', value: '' },
          ...(data || []),
          { text: 'None', value: 'NONE' }
        ];
      });
    },
    setContext() {
      this.context = document.getElementById('chart').getContext('2d');
    },
    initChart() {
      if (this.chartObj) {
        this.chartObj.destroy();
      }
      this.setContext();
      this.chartObj = new Chart(this.context, this.chartData);
    },
    generateData() {
      const labels = [...this.teams].map(t => t.text);

      const data = {
        late: {},
        now: {},
        done: {}
      };

      const total = {
        late: 0,
        done: 0,
        now: 0
      };

      labels.forEach(team => {
        data.late[team] = 0;
        data.now[team] = 0;
        data.done[team] = 0;
      });

      this.dataset.forEach(item => {
        const team = item?.team;
        if (team) {
          if (item.daysRemaining < 0) {
            data.late[team] += 1;
          } else if (item.daysRemaining >= 0 && item.daysRemaining <= 90) {
            data.now[team] += 1;
          } else if (item.daysRemaining > 90) {
            data.done[team] += 1;
          }
        }
      });

      let ds = labels.map(team => {
        const res = {
          team,
          done: data.done[team],
          late: data.late[team],
          now: data.now[team]
        };

        total.late += res.late;
        total.done += res.done;
        total.now += res.now;

        const sum = res.done + res.late + res.now;
        if (this.mode === 'percentage') {
          res.done = +((res.done / sum) * 100).toFixed(0);
          res.late = +((res.late / sum) * 100).toFixed(0);
          res.now = +((res.now / sum) * 100).toFixed(0);
        }
        return res;
      });

      if (this.sort === 'desc') {
        ds = sort({
          items: ds,
          desc: true,
          field: 'done'
        });
      }

      const colors = {
        late: Land.monitoringColors()[0],
        now: Land.monitoringColors()[1],
        done: Land.monitoringColors()[2]
      };

      const dataset = {
        late: [],
        now: [],
        done: [],
        labels: []
      };

      ds.forEach(i => {
        dataset.late.push(i.late);
        dataset.now.push(i.now);
        dataset.done.push(i.done);
        dataset.labels.push(i.team);
      });

      const chartData = {
        datasets: [],
        labels: dataset.labels
      };

      ['late', 'now', 'done'].forEach(key => {
        let label;
        if (this.mode === 'percentage') {
          label = `${((total[key] / this.dataset.length) * 100).toFixed(0)}% ${key} `;
        } else {
          label = `${total[key]} ${key} `;
        }
        const data = {
          label,
          data: dataset[key],
          backgroundColor: colors[key],
          key,
          percentage: this.mode === 'percentage'
        };
        chartData.datasets.push(data);
      });
      this.chartData.data = chartData;

      this.initChart();
    },
    fetchParcels() {
      const p = new Parcel();
      this.loading = true;
      p.list({ id: this.id })
        .then(parcels => {
          this.parcels = parcels;
          this.generateData();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    sort() {
      this.generateData();
    },
    mode() {
      this.generateData();
    },
    parcelsComputed: {
      handler() {
        this.generateData();
      }
    }
  }
};
</script>

<style>
</style>
